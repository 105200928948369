import { computed, Ref } from "vue";
import {
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { AvailableGenContractInterface } from "@/models/forwarding/GetDefaultConditionsResponse.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";

/**
 * @interface OrgWithExecutorRoleTypeInterface
 * @property {OrganizationViewInterface} organization
 * @property {ExecutorRoleTypeEnum} executorRoleType
 */
export interface OrgWithExecutorRoleTypeInterface {
  organization: OrganizationViewInterface;
  executorRoleType: ExecutorRoleTypeEnum;
}
export function useConditionsDescription(
  order: Ref<OrderItemInterface>,
  availableGenContracts: Ref<AvailableGenContractInterface[]>,
  selectedNotPartners: Ref<OrganizationViewInterface[]>
) {
  const genContractsUrl = computed(
    () => process.env.VUE_APP_REPUBLIC_ENDPOINT + "/GenContracts"
  );

  /**
   * @param {ExecutorRoleTypeEnum | null} partnerExecutorRoleType
   * @return {ExecutorRoleTypeEnum | null}
   * @private
   */
  function _getOrganizationMissingExecutorRoleType(
    partnerExecutorRoleType: ExecutorRoleTypeEnum | null
  ) {
    switch (partnerExecutorRoleType) {
      case ExecutorRoleTypeEnum.Forwarding:
        return ExecutorRoleTypeEnum.Delivery;
      case ExecutorRoleTypeEnum.Delivery:
        return ExecutorRoleTypeEnum.Forwarding;
      case ExecutorRoleTypeEnum.Any:
        return null;
      default:
        return ExecutorRoleTypeEnum.Any;
    }
  }

  /**
   * Фильтрует организации по доступности или недоступности базового ШУ
   * @param {OrganizationViewInterface[]} organizations
   * @param {AvailableGenContractInterface?} availableForwardingGenContract
   * @param {AvailableGenContractInterface?} availableDeliveryGenContract
   * @param {boolean} isAvailable
   * @return {OrgWithExecutorRoleTypeInterface[]}
   * @private
   */
  function _filterOrganizationByAvailableGenContract(
    organizations: OrganizationViewInterface[],
    availableForwardingGenContract: AvailableGenContractInterface | undefined,
    availableDeliveryGenContract: AvailableGenContractInterface | undefined,
    isAvailable: boolean
  ): OrgWithExecutorRoleTypeInterface[] {
    return organizations
      .map(org => {
        const missingRoleType = _getOrganizationMissingExecutorRoleType(
          org.partnerExecutorRoleType
        );

        const isForwardingAvailable =
          isAvailable && availableForwardingGenContract != null;
        const isDeliveryAvailable =
          isAvailable && availableDeliveryGenContract != null;
        const isForwardingUnavailable =
          !isAvailable && availableForwardingGenContract == null;
        const isDeliveryUnavailable =
          !isAvailable && availableDeliveryGenContract == null;

        switch (missingRoleType) {
          case ExecutorRoleTypeEnum.Forwarding:
            return isForwardingAvailable || isForwardingUnavailable
              ? {
                  organization: org,
                  executorRoleType: ExecutorRoleTypeEnum.Forwarding
                }
              : null;
          case ExecutorRoleTypeEnum.Delivery:
            return isDeliveryAvailable || isDeliveryUnavailable
              ? {
                  organization: org,
                  executorRoleType: ExecutorRoleTypeEnum.Delivery
                }
              : null;
          case ExecutorRoleTypeEnum.Any:
            if (
              (isAvailable &&
                !availableForwardingGenContract &&
                !availableDeliveryGenContract) ||
              (!isAvailable &&
                availableForwardingGenContract &&
                availableDeliveryGenContract)
            ) {
              return null;
            }
            if (
              (isDeliveryAvailable && !availableForwardingGenContract) ||
              (isDeliveryUnavailable && availableForwardingGenContract)
            ) {
              return {
                organization: org,
                executorRoleType: ExecutorRoleTypeEnum.Delivery
              };
            }
            if (
              (isForwardingAvailable && !availableDeliveryGenContract) ||
              (isForwardingUnavailable && availableDeliveryGenContract)
            ) {
              return {
                organization: org,
                executorRoleType: ExecutorRoleTypeEnum.Forwarding
              };
            }
            return {
              organization: org,
              executorRoleType: ExecutorRoleTypeEnum.Any
            };
          default:
            return null;
        }
      })
      .filter(x => x != null) as OrgWithExecutorRoleTypeInterface[];
  }

  /**
   * @param {ExecutorRoleTypeEnum} executorRoleType
   * @param {OrderTypeEnum} sendType
   * @return {AvailableGenContractInterface | undefined}
   * @private
   */
  function getAvailableGenContract(
    executorRoleType: ExecutorRoleTypeEnum,
    sendType: OrderTypeEnum
  ) {
    return availableGenContracts.value.find(genContract => {
      const hasAnyCondition =
        sendType === OrderTypeEnum.ToExecutor
          ? genContract.forwardingConditions
              ?.daysAfterDocumentsReceiveForPayment != null
          : genContract.openConditions?.daysAfterDocumentsReceiveForPayment !=
            null;
      return (
        genContract.executorRoleType === executorRoleType && hasAnyCondition
      );
    });
  }

  /**
   * Генерирует название роли, как подсказку по организации
   * @param {ExecutorRoleTypeEnum} executorRoleType
   * @return {string}
   * @private
   */
  function generateExecutorRoleTypeTitle(
    executorRoleType: ExecutorRoleTypeEnum
  ): string {
    if (executorRoleType === ExecutorRoleTypeEnum.Forwarding)
      return " (экспедитор)";

    if (executorRoleType === ExecutorRoleTypeEnum.Delivery)
      return " (перевозчик)";

    return "";
  }

  /**
   * @param {string[]} genContractIds
   * @param {string} linkText
   * @return {string}
   * @private
   */
  function generateGenContractLink(genContractIds: string[], linkText: string) {
    return `<a target="_blank" href="${genContractsUrl.value}">${linkText}</a>`;
  }

  /**
   * Генерирует текст описание условий при назначении
   * @param {AvailableGenContractInterface?} availableForwardingGenContract
   * @param {AvailableGenContractInterface?} availableDeliveryGenContract
   * @param {string} descriptionBase
   * @return {string}
   * @private
   */
  function generateForwardingConditionsDescription(
    availableForwardingGenContract: AvailableGenContractInterface | undefined,
    availableDeliveryGenContract: AvailableGenContractInterface | undefined,
    descriptionBase: string
  ) {
    const notPartnersWithoutAvailableGenContract = _filterOrganizationByAvailableGenContract(
      selectedNotPartners.value,
      availableForwardingGenContract,
      availableDeliveryGenContract,
      false
    );
    const notPartnersWithAvailableGenContract = _filterOrganizationByAvailableGenContract(
      selectedNotPartners.value,
      availableForwardingGenContract,
      availableDeliveryGenContract,
      true
    );
    const orgWithGenContractTitles = notPartnersWithAvailableGenContract
      .map(
        x =>
          x.organization.title +
          generateExecutorRoleTypeTitle(x.executorRoleType)
      )
      .join(", ");
    const orgWithNoGenContractTitles = notPartnersWithoutAvailableGenContract
      .map(
        x =>
          x.organization.title +
          generateExecutorRoleTypeTitle(x.executorRoleType)
      )
      .join(", ");
    const defaultConditionsText =
      descriptionBase +
      `: ${orgWithNoGenContractTitles}, &mdash; требуется указать условия`;

    if (
      order.value.executorRoleType === ExecutorRoleTypeEnum.Forwarding ||
      order.value.executorRoleType === ExecutorRoleTypeEnum.Delivery
    ) {
      const genContractLink =
        order.value.executorRoleType === ExecutorRoleTypeEnum.Forwarding
          ? generateGenContractLink(
              [availableForwardingGenContract?.genContractId].filter(
                x => x != undefined
              ) as string[],
              "шаблон условий договора"
            )
          : generateGenContractLink(
              [availableDeliveryGenContract?.genContractId].filter(
                x => x != undefined
              ) as string[],
              "шаблон условий договора"
            );
      const genContractText =
        descriptionBase +
        `: ${orgWithGenContractTitles}, &mdash; используется базовый ${genContractLink}`;
      return notPartnersWithAvailableGenContract.length > 0
        ? genContractText
        : defaultConditionsText;
    }

    const genContractLink = generateGenContractLink(
      [
        availableForwardingGenContract?.genContractId,
        availableDeliveryGenContract?.genContractId
      ].filter(x => x != null) as string[],
      "шаблон условий договора"
    );
    const genContractText =
      descriptionBase +
      `: ${orgWithGenContractTitles}, &mdash; используется базовый ${genContractLink}`;

    if (
      notPartnersWithAvailableGenContract.length > 0 &&
      notPartnersWithoutAvailableGenContract.length > 0
    ) {
      return genContractText + "<br/>" + defaultConditionsText;
    }

    if (notPartnersWithAvailableGenContract.length > 0) return genContractText;

    return defaultConditionsText;
  }

  /**
   * Генерирует текст описание условий при отправке в открытый хаб
   * @param {AvailableGenContractInterface?} availableForwardingGenContract
   * @param {AvailableGenContractInterface?} availableDeliveryGenContract
   * @param {string} descriptionBase
   * @return {string}
   * @private
   */
  function _generateHubConditionsDescription(
    availableForwardingGenContract: AvailableGenContractInterface | undefined,
    availableDeliveryGenContract: AvailableGenContractInterface | undefined,
    descriptionBase: string
  ) {
    const availableForwardingGenContractId =
      availableForwardingGenContract?.genContractId;
    const availableDeliveryGenContractId =
      availableDeliveryGenContract?.genContractId;
    let genContractText;

    if (order.value.executorRoleType === ExecutorRoleTypeEnum.Forwarding) {
      genContractText =
        descriptionBase +
        `(экспедирование) используется базовый ` +
        generateGenContractLink(
          [availableForwardingGenContractId].filter(
            x => x != undefined
          ) as string[],
          "шаблон условий договора"
        );
      return availableForwardingGenContractId ? genContractText : "";
    }

    if (order.value.executorRoleType === ExecutorRoleTypeEnum.Delivery) {
      genContractText =
        descriptionBase +
        `(перевозка) используется базовый ` +
        generateGenContractLink(
          [availableDeliveryGenContractId].filter(
            x => x != undefined
          ) as string[],
          "шаблон условий договора"
        );
      return availableDeliveryGenContractId ? genContractText : "";
    }

    /**
     * @type {string[]}
     */
    const genContractIds = [
      availableDeliveryGenContractId,
      availableForwardingGenContractId
    ].filter(x => x != null) as string[];
    genContractText =
      descriptionBase +
      ` используется базовый ` +
      generateGenContractLink(genContractIds, "шаблон условий договора");
    if (genContractIds.length === 0) return "";
    if (genContractIds.length === 2) return genContractText;

    const defaultConditionText =
      descriptionBase +
      (!availableDeliveryGenContractId ? "(перевозка)" : "(экспедирование)") +
      " необходимо задать условия:";
    genContractText =
      descriptionBase +
      (availableDeliveryGenContractId ? "(перевозка)" : "(экспедирование)") +
      ` используется базовый ` +
      generateGenContractLink(genContractIds, "шаблон условий договора");
    return genContractText + "<br/>" + defaultConditionText;
  }

  const baseConditionsDescription = computed(() => {
    const descriptionBase =
      "Для компаний" +
      (order.value.orderType === OrderTypeEnum.ToExecutor
        ? " без утвержденных условий"
        : "");
    /**
     * @type {AvailableGenContractInterface|undefined}
     */
    const availableForwardingGenContract = getAvailableGenContract(
      ExecutorRoleTypeEnum.Forwarding,
      order.value.orderType
    );
    /**
     * @type {AvailableGenContractInterface|undefined}
     */
    const availableDeliveryGenContract = getAvailableGenContract(
      ExecutorRoleTypeEnum.Delivery,
      order.value.orderType
    );

    if (order.value.orderType === OrderTypeEnum.ToHub) {
      return _generateHubConditionsDescription(
        availableForwardingGenContract,
        availableDeliveryGenContract,
        descriptionBase
      );
    }

    if (selectedNotPartners.value.length === 0) return "";

    return generateForwardingConditionsDescription(
      availableForwardingGenContract,
      availableDeliveryGenContract,
      descriptionBase
    );
  });
  const showConditionFields = computed(() => {
    if (order.value.orderType === OrderTypeEnum.ToHub) {
      const hasOpenForwardingConditions =
        getAvailableGenContract(
          ExecutorRoleTypeEnum.Forwarding,
          order.value.orderType
        ) != undefined;
      const hasOpenDeliveryConditions =
        getAvailableGenContract(
          ExecutorRoleTypeEnum.Delivery,
          order.value.orderType
        ) != undefined;
      switch (order.value.executorRoleType) {
        case ExecutorRoleTypeEnum.Forwarding:
          return !hasOpenForwardingConditions;
        case ExecutorRoleTypeEnum.Delivery:
          return !hasOpenDeliveryConditions;
        case ExecutorRoleTypeEnum.Any:
        default:
          return !(hasOpenDeliveryConditions && hasOpenForwardingConditions);
      }
    }

    if (selectedNotPartners.value.length === 0) return false;

    const hasForwardForwardingConditions =
      getAvailableGenContract(
        ExecutorRoleTypeEnum.Forwarding,
        order.value.orderType
      ) != undefined;
    const hasForwardDeliveryConditions =
      getAvailableGenContract(
        ExecutorRoleTypeEnum.Delivery,
        order.value.orderType
      ) != undefined;

    return selectedNotPartners.value.some(partner => {
      switch (order.value.executorRoleType) {
        case ExecutorRoleTypeEnum.Forwarding:
          return (
            partner.partnerExecutorRoleType !==
              ExecutorRoleTypeEnum.Forwarding && !hasForwardForwardingConditions
          );
        case ExecutorRoleTypeEnum.Delivery:
          return (
            partner.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Delivery &&
            !hasForwardDeliveryConditions
          );
        case ExecutorRoleTypeEnum.Any:
        default:
          return (
            partner.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Any &&
            (partner.partnerExecutorRoleType == null
              ? !hasForwardForwardingConditions || !hasForwardDeliveryConditions
              : partner.partnerExecutorRoleType ===
                ExecutorRoleTypeEnum.Delivery
              ? !hasForwardForwardingConditions
              : !hasForwardDeliveryConditions)
          );
      }
    });
  });

  return {
    baseConditionsDescription,
    showConditionFields
  };
}
